<template>
  <div class="SynastryInterpretCard">
    <div class="SynastryInterpretCard__main">
      <div class="SynastryInterpretCard__main__info">
        <img :src="findImg(interpretData.Astral1)" alt="" />
        <div class="SynastryInterpretCard__main__info__astralName ml-1">
          {{ interpretData.AstralName1 }}
        </div>
        <div class="SynastryInterpretCard__main__info__angle">
          {{ interpretData.Angle1 }}
        </div>

        <div class="SynastryInterpretCard__main__info__AspectName">
          {{ interpretData.AspectName }}
        </div>
        <img :src="findImg(interpretData.Astral2)" alt="" />
        <div class="SynastryInterpretCard__main__info__astralName ml-1">
          {{ interpretData.AstralName2 }}
        </div>
        <div class="SynastryInterpretCard__main__info__angle">
          {{ interpretData.Angle2 }}
        </div>
      </div>
      <div class="SynastryInterpretCard__main__title" v-show="!openContent">
        {{ interpretData.Title }}
      </div>
    </div>
    <div class="SynastryInterpretCard__content mt_6" v-show="openContent">
      <div class="SynastryInterpretCard__content__tab">
        <div
          class="SynastryInterpretCard__content__tab__item"
          :class="{ active: tabActive === 0 }"
          @click="tabActive = 0"
        >
          感情
        </div>
        <div
          class="SynastryInterpretCard__content__tab__item"
          :class="{ active: tabActive === 1 }"
          @click="tabActive = 1"
        >
          朋友
        </div>
        <div
          class="SynastryInterpretCard__content__tab__item"
          :class="{ active: tabActive === 2 }"
          @click="tabActive = 2"
        >
          職場
        </div>
        <div
          class="SynastryInterpretCard__content__tab__item"
          :class="{ active: tabActive === 3 }"
          @click="tabActive = 3"
        >
          親子
        </div>
      </div>
      <div class="SynastryInterpretCard__content__article">
        <div
          v-show="tabActive === 0"
          v-html="interpretData.ContentOfEmotion"
        ></div>
        <div
          v-show="tabActive === 1"
          v-html="interpretData.ContentOfFriend"
        ></div>
        <div
          v-show="tabActive === 2"
          v-html="interpretData.ContentOfParentAndChild"
        ></div>
        <div
          v-show="tabActive === 3"
          v-html="interpretData.ContentOfWorkplace"
        ></div>
      </div>
    </div>
    <div
      class="SynastryInterpretCard__openBtn"
      @click="openContent = !openContent"
    >
      <img
        src="@/assets/icon_downward.svg"
        :style="openContent ? 'transform:rotate(180deg)' : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["interpretData"],
  data() {
    return {
      planets: require("@/js/planets"),
      openContent: false,
      tabActive: 0,
    };
  },
  methods: {
    findImg(Name) {
      let planet = this.planets.find((planet) => planet.eName == Name);
      if (!planet) console.log(Name);
      return planet.imageUrl;
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";

.SynastryInterpretCard {
  width: 100%;
  background: #f0f2f4;
  box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
    4px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px 16px 12px;
  @include mobile {
    padding: 16px;
  }
  &__main {
    width: 100%;
    &__info {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 26px;
      color: #798da5;
      @include mobile {
        font-size: 16px;
      }
      img {
        width: 30px;
        height: 30px;
        margin-top: 2px;
        width: 20px;
      }
      &__astralName {
        display: inline-block;
      }
      &__AspectName {
        margin: 0 20px;
        min-width: 66px;
        text-align: center;
        @include mobile {
          min-width: 50px;
          margin: 0 16px;
        }
      }
      &__angle {
        text-align: center;
        min-width: 28px;
        margin-left: 4px;
        box-shadow: rgb(255, 255, 255) 2px 2px 4px,
          rgba(0, 0, 0, 0.15) 2px 2px 4px inset;
        font-size: 14px;
        line-height: 1.5;
        padding: 2px 2px 2px 4px;
        border-radius: 6px;
        @include mobile {
          min-width: 25px;
          font-size: 12px;
          padding: 2px 2px 2px 4px;
        }
      }
    }
    &__title {
      font-size: 14px;
      line-height: 18px;
      color: #798da5;
      margin-top: 12px;
      @include mobile {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &__content {
    &__tab {
      display: flex;
      justify-content: space-around;
      position: relative;
      &__item {
        flex-grow: 1;
        font-size: 14px;
        line-height: 18px;
        color: #868686;
        text-align: center;
        cursor: pointer;
        position: relative;
        &:hover {
          color: #798da5;
        }
        &.active {
          color: #798da5;
          &:after {
            content: "";
            position: absolute;
            height: 4px;
            width: 100%;
            border-radius: 2px;
            left: 0;
            bottom: -8px;
            background-color: #798da5;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 100%;
        box-shadow: 2px 2px 4px 0 #ffffff,
          inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        left: 0;
        bottom: -8px;
      }
    }
    &__article {
      font-size: 14px;
      line-height: 18px;
      color: #9b9b9b;
      margin-top: 20px;
      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__openBtn {
    margin-left: auto;
    width: 20px;
    height: 20px;
    margin-top: 4px;
    cursor: pointer;
    @include mobile {
      width: 16px;
      height: 16px;
    }
    img {
      width: 100%;
    }
  }
}
</style>