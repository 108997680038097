<template>
  <div class="SynastryInterpret">
    <div class="SynastryInterpret__content">
      <SynastryInterpretCard
        v-for="(item, index) in interpretations"
        :key="index"
        :interpretData="item"
      />
    </div>
    <transition name="fade">
      <div class="SynastryInterpret__notice" v-show="interpretTabNotice">
        <img src="@/assets/上下滑動.svg" alt />
        <span class="mt-3">上下滑動瀏覽解讀</span>
      </div>
    </transition>
  </div>
</template>

<script>
import SynastryInterpretCard from "./SynastryInterpretCard.vue";

export default {
  props: ["interpretations"],
  components: {
    SynastryInterpretCard,
  },
  data() {
    return {
      planets: require("@/js/planets"),
      interpretTabNotice: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.interpretTabNotice = false;
    }, 3000);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.SynastryInterpret {
  width: 100%;
  box-shadow: -2px -2px 8px 0 #fff, -4px -4px 12px 0 #fff,
    4px 4px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
  @include pad {
    padding: 0;
  }
  &__content {
    flex-grow: 0;
    width: 100%;
    height: 750px;
    padding: 4px 30px 12px;
    overflow-y: auto;
    @include text-3(#292d2d);
    font-size: 18px;
    margin-top: 28px;
    margin-bottom: 28px;
    line-height: 30px !important;
    transition: 0.5s;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $theme-color-1;
    }
    @include pad {
      font-size: 16px;
      line-height: 26px !important;
      padding: 4px 18px 12px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  &__notice {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(240, 242, 244, 0.8);
    backdrop-filter: blur(10px);
    z-index: 5;
    @include center;
    flex-direction: column;
    font-size: 14px;
    line-height: 14px;
    color: #ab956c;
    img {
      animation: upAndDown 1s infinite;
    }
    @keyframes upAndDown {
      0% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-32px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}
.SynastryInterpretCard:not(:last-child) {
  margin-bottom: 12px;
}
</style>