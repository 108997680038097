<template>
  <div class="ScoreStep">
    <!-- 一般資訊 -->
    <div class="ScoreStep__main">
      <div class="ScoreStep__main__item">
        <div class="ScoreStep__main__item__pic">
          <img src="@/assets/雙人合盤/girl_left.svg" alt="" />
        </div>
        <div
          class="ScoreStep__main__item__name"
          @click="detailOpen = !detailOpen"
        >
          {{ target1.Name }}
        </div>
      </div>

      <div class="ScoreStep__main__info">
        <div class="ScoreStep__main__info__imgLeft">
          <img src="@/assets/雙人合盤/scoreStep_left.svg" alt="" />
        </div>
        <div class="ScoreStep__main__info__textTop">兩顆星星之間距離</div>
        <div class="ScoreStep__main__info__step">
          {{ animatedScore }} <span>步</span>
        </div>
        <div class="ScoreStep__main__info__textBottom">遙遠互望！</div>
        <div class="ScoreStep__main__info__imgRight">
          <img src="@/assets/雙人合盤/scoreStep_right.svg" alt="" />
        </div>
      </div>

      <div class="ScoreStep__main__item">
        <div class="ScoreStep__main__item__pic">
          <img src="@/assets/雙人合盤/boy_right.svg" alt="" />
        </div>
        <div
          class="ScoreStep__main__item__name"
          @click="detailOpen = !detailOpen"
        >
          {{ target2.Name }}
        </div>
      </div>
      <div class="ScoreStep__main__openBtn" @click="detailOpen = !detailOpen">
        <img
          src="@/assets/icon_downward.svg"
          :style="detailOpen ? 'transform:rotate(180deg)' : ''"
        />
      </div>
    </div>
    <!-- 詳細資訊 -->
    <div class="ScoreStep__detail" v-if="detailOpen">
      <div class="ScoreStep__detail__birth">
        {{ `${target1.BirthPlace}` }}<br />
        {{ birthFormat(target1.BirthTime) }}
      </div>
      <div class="user__div"></div>
      <div class="ScoreStep__detail__birth">
        {{ `${target2.BirthPlace}` }}<br />
        {{ birthFormat(target2.BirthTime) }}
      </div>
    </div>
  </div>
</template>

<script>
import phaseMixin from "@/mixin/phaseMixin.js";

export default {
  mixins: [phaseMixin],
  props: {
    target1: {
      type: Object,
      default: () => {},
    },
    target2: {
      type: Object,
      default: () => {},
    },
    scoreStepNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      detailOpen: false,
      animatedScore: "00", // 用於顯示動畫的數字
    };
  },
  mounted() {
    this.startScoreAnimation();
  },
  methods: {
    startScoreAnimation() {
      let counter = 0;
      const interval = setInterval(() => {
        // 生成隨機的兩位數字
        this.animatedScore = String(Math.floor(Math.random() * 100)).padStart(
          2,
          "0"
        );
        counter += 30; // 每次增加45毫秒
        if (counter >= 3000) {
          // 5秒後停止動畫
          clearInterval(interval);
          this.animatedScore = String(this.scoreStepNumber).padStart(2, "0"); // 顯示真正的數字
        }
      }, 30); // 每45毫秒更新一次
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.ScoreStep {
  width: 100%;
  padding: 32px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -2px -2px 8px 0 #fff, -4px -4px 12px 0 #fff,
    4px 4px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  @include pad {
    padding: 0 24px;
    box-shadow: none;
  }
  .ScoreStep__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    position: relative;
    .ScoreStep__main__item {
      width: 93px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        width: 82px;
      }
      .ScoreStep__main__item__pic {
        width: auto;
        height: 100px;
        @include mobile {
          height: 88px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ScoreStep__main__item__name {
        margin-top: 8px;
        font-size: 19px;
        line-height: 1.5;
        color: #868686;
        cursor: pointer;
        @include mobile {
          font-size: 15px;
        }
      }
    }
    .ScoreStep__main__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      &__textTop {
        font-size: 14px;
        line-height: 24px;
        color: #868686;
        letter-spacing: 4%;
        @include mobile {
          line-height: 20px;
        }
      }
      &__step {
        font-size: 48px;
        line-height: 38px;
        color: #ab956c;
        margin-top: 18px;
        margin-bottom: 20px;
        @include mobile {
          font-size: 40px;
          line-height: 30px;
        }
        span {
          font-size: 16px;
          @include mobile {
            font-size: 14px;
          }
        }
      }
      &__textBottom {
        font-size: 14px;
        line-height: 24px;
        color: #868686;
        letter-spacing: 4%;
        @include mobile {
          line-height: 20px;
        }
      }
      .ScoreStep__main__info__imgLeft,
      .ScoreStep__main__info__imgRight {
        position: absolute;
        top: 50px;
        @include mobile {
          top: 40px;
        }
        img {
          width: 56px;
          @include mobile {
            width: 34px;
          }
        }
      }
      &__imgLeft {
        left: -60px;
        @include mobile {
          left: -20px;
        }
      }
      &__imgRight {
        right: -60px;
        @include mobile {
          right: -20px;
        }
      }
    }
    &__openBtn {
      position: absolute;
      right: -12px;
      bottom: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
      @include mobile {
        width: 20px;
        height: 20px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ScoreStep__detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .ScoreStep__detail__birth {
      font-size: 12px;
      color: #666666;
      text-align: center;
    }
  }
}
</style>