var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SynastryMain" }, [
    _c(
      "div",
      { staticClass: "SynastryMain__interpret" },
      [
        _c("Tab", {
          staticClass: "SynastryMain__interpret__tab hidden-laptop-down",
          attrs: { tabList: _vm.tabList, current: _vm.showType },
          on: { setActiveTab: _vm.changeTypeHandler }
        }),
        _c("SynastryInterpret", {
          attrs: { interpretations: _vm.interpretations }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "SynastryMain__content" },
      [
        _c("ScoreStep", {
          attrs: {
            target1: _vm.target1,
            target2: _vm.target2,
            scoreStepNumber: _vm.scoreStepNumber
          }
        }),
        _c("Tab", {
          staticClass: "SynastryMain__content__tab hidden-laptop-up",
          attrs: { tabList: _vm.tabList, current: _vm.showType },
          on: { setActiveTab: _vm.changeTypeHandler }
        }),
        _vm.Data1
          ? _c(
              "div",
              { staticClass: "astrolabeDBL" },
              [
                _c("div", { staticClass: "astrolabeDBL__wrap" }, [
                  _c(
                    "div",
                    { staticClass: "swiper" },
                    [
                      _c(
                        "swiper",
                        { attrs: { id: "SW", options: _vm.swiperAstrolabe } },
                        _vm._l(_vm.style, function(item) {
                          return _c(
                            "swiper-slide",
                            { key: item.id, staticClass: "astrolabeDBL__item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "astrolabeDBL__bg",
                                  attrs: { id: "astrolabeDBL__bg" }
                                },
                                [
                                  _vm.dataChange
                                    ? _c("canvasAstrolabe", {
                                        staticClass: "canvas-pan",
                                        attrs: {
                                          width: _vm.paramPan.width,
                                          configItem: _vm.panConfigItem,
                                          panStyle: item.name,
                                          type: _vm.paramPan.type,
                                          data1: _vm.Data1.AstralRadians,
                                          data2: _vm.Data2
                                            ? _vm.Data2.AstralRadians
                                            : null,
                                          Ret1: _vm.Data1.RetAstralSet,
                                          role: "DBL"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      ),
                      _vm.style.length > 1
                        ? _c("div", { staticClass: "switch" }, [
                            _c("div", {
                              staticClass: "swiper-button-prev",
                              attrs: { slot: "button-prev" },
                              slot: "button-prev"
                            }),
                            _c("div", {
                              staticClass: "swiper-pagination",
                              attrs: { slot: "pagination" },
                              slot: "pagination"
                            }),
                            _c("div", {
                              staticClass: "swiper-button-next",
                              attrs: { slot: "button-next" },
                              slot: "button-next"
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "astrolabeDBL__setting",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openSetup()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_setting.svg"),
                        alt: ""
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "astrolabeDBL__change hidden-pad-up",
                    on: { click: _vm.toListDBL }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_change.svg"),
                        alt: ""
                      }
                    })
                  ]
                ),
                _c("SetAstrolabe", {
                  attrs: { role: "DBL", activeSetup: _vm.activeSetup },
                  on: {
                    close: function($event) {
                      _vm.activeSetup = false
                    },
                    refreshData: _vm.refreshData
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }