<template>
  <div class="tab" v-if="tabList">
    <div
      class="tab__item"
      :class="{ activeTab: activeTab.value == tabList[0].value }"
      @click="activeTab = tabList[0]"
    >
      {{ tabList[0].label }}
    </div>
    <div
      class="tab__item"
      :class="{ activeTab: activeTab.value == tabList[1].value }"
      @click="activeTab = tabList[1]"
    >
      {{ tabList[1].label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabList", "current"],
  data() {
    return {};
  },
  computed: {
    activeTab: {
      get() {
        if (!this.tabList) return {};
        return this.tabList.find((tab) => tab.value == this.current);
      },
      set(tab) {
        this.$emit("setActiveTab", tab.value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.tab {
  width: 100%;
  @include align(center, center);

  .tab__item {
    @include btn(50%, 48px, transparent, $theme-color-1, 20px);
    border: solid 2px $theme-color-1;
    transition: 0.2s;

    &.activeTab {
      background-color: $theme-color-1;
      color: $theme-color-3;
      border: none;
      font-weight: bold;
    }

    @include mobile {
      height: 32px;
      font-size: 14px;
    }
  }
}
</style>