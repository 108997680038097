var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ScoreStep" }, [
    _c("div", { staticClass: "ScoreStep__main" }, [
      _c("div", { staticClass: "ScoreStep__main__item" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "ScoreStep__main__item__name",
            on: {
              click: function($event) {
                _vm.detailOpen = !_vm.detailOpen
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.target1.Name) + "\n      ")]
        )
      ]),
      _c("div", { staticClass: "ScoreStep__main__info" }, [
        _vm._m(1),
        _c("div", { staticClass: "ScoreStep__main__info__textTop" }, [
          _vm._v("兩顆星星之間距離")
        ]),
        _c("div", { staticClass: "ScoreStep__main__info__step" }, [
          _vm._v("\n        " + _vm._s(_vm.animatedScore) + " "),
          _c("span", [_vm._v("步")])
        ]),
        _c("div", { staticClass: "ScoreStep__main__info__textBottom" }, [
          _vm._v("遙遠互望！")
        ]),
        _vm._m(2)
      ]),
      _c("div", { staticClass: "ScoreStep__main__item" }, [
        _vm._m(3),
        _c(
          "div",
          {
            staticClass: "ScoreStep__main__item__name",
            on: {
              click: function($event) {
                _vm.detailOpen = !_vm.detailOpen
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.target2.Name) + "\n      ")]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "ScoreStep__main__openBtn",
          on: {
            click: function($event) {
              _vm.detailOpen = !_vm.detailOpen
            }
          }
        },
        [
          _c("img", {
            style: _vm.detailOpen ? "transform:rotate(180deg)" : "",
            attrs: { src: require("@/assets/icon_downward.svg") }
          })
        ]
      )
    ]),
    _vm.detailOpen
      ? _c("div", { staticClass: "ScoreStep__detail" }, [
          _c("div", { staticClass: "ScoreStep__detail__birth" }, [
            _vm._v("\n      " + _vm._s("" + _vm.target1.BirthPlace)),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.birthFormat(_vm.target1.BirthTime)) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "user__div" }),
          _c("div", { staticClass: "ScoreStep__detail__birth" }, [
            _vm._v("\n      " + _vm._s("" + _vm.target2.BirthPlace)),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.birthFormat(_vm.target2.BirthTime)) +
                "\n    "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ScoreStep__main__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/girl_left.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ScoreStep__main__info__imgLeft" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/scoreStep_left.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ScoreStep__main__info__imgRight" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/雙人合盤/scoreStep_right.svg"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ScoreStep__main__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/boy_right.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }