var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SynastryInterpret" },
    [
      _c(
        "div",
        { staticClass: "SynastryInterpret__content" },
        _vm._l(_vm.interpretations, function(item, index) {
          return _c("SynastryInterpretCard", {
            key: index,
            attrs: { interpretData: item }
          })
        }),
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.interpretTabNotice,
                expression: "interpretTabNotice"
              }
            ],
            staticClass: "SynastryInterpret__notice"
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/上下滑動.svg"), alt: "" }
            }),
            _c("span", { staticClass: "mt-3" }, [_vm._v("上下滑動瀏覽解讀")])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }