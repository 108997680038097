<template>
  <div class="SynastryMain">
    <!--解讀內容-->
    <div class="SynastryMain__interpret">
      <Tab
        :tabList="tabList"
        :current="showType"
        @setActiveTab="changeTypeHandler"
        class="SynastryMain__interpret__tab hidden-laptop-down"
      />
      <SynastryInterpret :interpretations="interpretations"></SynastryInterpret>
    </div>
    <!--主要內容-->
    <div class="SynastryMain__content">
      <!-- 使用者資訊 -->
      <ScoreStep
        :target1="target1"
        :target2="target2"
        :scoreStepNumber="scoreStepNumber"
      />
      <Tab
        :tabList="tabList"
        :current="showType"
        @setActiveTab="changeTypeHandler"
        class="SynastryMain__content__tab hidden-laptop-up"
      />
      <!-- 星盤 -->
      <div class="astrolabeDBL" v-if="Data1">
        <div class="astrolabeDBL__wrap">
          <!-- swiper -->
          <div class="swiper">
            <swiper id="SW" :options="swiperAstrolabe">
              <swiper-slide
                class="astrolabeDBL__item"
                v-for="item in style"
                :key="item.id"
              >
                <div class="astrolabeDBL__bg" id="astrolabeDBL__bg">
                  <canvasAstrolabe
                    v-if="dataChange"
                    class="canvas-pan"
                    :width="paramPan.width"
                    :configItem="panConfigItem"
                    :panStyle="item.name"
                    :type="paramPan.type"
                    :data1="Data1.AstralRadians"
                    :data2="Data2 ? Data2.AstralRadians : null"
                    :Ret1="Data1.RetAstralSet"
                    role="DBL"
                  ></canvasAstrolabe>
                </div>
              </swiper-slide>
            </swiper>
            <div class="switch" v-if="style.length > 1">
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </div>
          </div>
          <!-- swiper end -->
        </div>
        <!-- 星盤設置開關  -->
        <div class="astrolabeDBL__setting" @click.stop="openSetup()">
          <img src="@/assets/icon_setting.svg" alt />
        </div>
        <div class="astrolabeDBL__change hidden-pad-up" @click="toListDBL">
          <img src="@/assets/icon_change.svg" alt />
        </div>
        <!-- 星盤設置  -->
        <SetAstrolabe
          role="DBL"
          :activeSetup="activeSetup"
          @close="activeSetup = false"
          @refreshData="refreshData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";
import SetAstrolabe from "@/components/SetAstrolabe.vue";
import SynastryInterpret from "@/components/雙人合盤/SynastryInterpret.vue";
import phaseMixin from "@/mixin/phaseMixin.js";
import ScoreStep from "@/components/雙人合盤/ScoreStep.vue";
import Tab from "@/components/Tab.vue";

export default {
  mixins: [phaseMixin],
  components: {
    canvasAstrolabe,
    SetAstrolabe,
    SynastryInterpret,
    ScoreStep,
    Tab,
  },
  data() {
    return {
      ready: false,
      Data1: null, //星盤設置設定檔
      Data2: null, //星盤設置設定檔
      target1: {},
      target2: {},
      tabList: [
        { label: "比較", value: "type1" },
        { label: "組合", value: "type3" },
      ],
      //強迫更新用
      dataChange: true,
      //星盤設置開關
      activeSetup: false,
      //星盤Swiper
      swiperAstrolabe: {
        initialSlide: 0,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "custom-bullet",
          bulletActiveClass: "custom-bullet-active",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swiper = document.getElementById("SW").swiper;
            let i = swiper.activeIndex;
            this.changeShow(i);
          },
        },
      },
      style: [
        { name: "A32", displayName: "標準", show: false, id: "alert2" },
        { name: "JOE2", displayName: "專業", show: false, id: "alert4" },
      ],
      //星盤所需資料
      paramPan: {
        width: 520,
        maxWidth: 600, //最大屏幕宽度
        type: 1,
        data2: null,
        panStyle: "A32",
      },
      showType: "type1",
      userMobileInfoOpen: false,
      synastryType: 0, // 0:比較盤 1:組合盤
      interpretations: [], //解讀
      scoreStepNumber: 0,
    };
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state.DBL.mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state.DBL.planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    UserData() {
      return this.$store.state.userData; //星盤設置設定檔
    },
    detailNeed_fixed() {
      let DetailNeed = this.$store.state["DBL"].mainPlanetList.concat(
        this.$store.state["DBL"].planetList
      );
      return [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];
    },
  },

  async created() {
    if (process.env.NODE_ENV == "production") {
      this.$router.push({ path: "/" });
    }
    if (window.localStorage.getItem("login_status")) {
      this.$store.commit("updateLoginStatus", true);
    } else {
      this.$store.commit("updateLoginStatus", false);
    }
    if (window.innerWidth < 1263) {
      this.paramPan.width = 760 - 4;
    }
    if (window.innerWidth < 860) {
      this.paramPan.width = window.innerWidth - 4;
    }
    if (window.innerWidth < 768) {
      this.paramPan.width = window.innerWidth - 12;
    }
    await this.init();
    this.ready = true;
  },
  methods: {
    //取得主要資料
    async init() {
      try {
        let synastrysDataResult = await this.$API.GET_SynastrysData({
          SynastryId: this.$route.params.SynastryId,
        });
        //星體弧度資料
        this.target1 = synastrysDataResult.Data[0];
        this.target2 = synastrysDataResult.Data[1];
        //放人進去Data
        this.Data1 = synastrysDataResult.Data[0].AstralInfo;
        this.Data2 = synastrysDataResult.Data[1].AstralInfo;

        await this.getInterpretations(); //取得解讀
      } catch (e) {
        // console.error("API 取得合盤明細失敗", e);
      }
    },
    //取得解讀
    async getInterpretations() {
      try {
        let result = await this.$API.POST_SynastryInterpretation({
          SynastryId: this.$route.params.SynastryId,
          SynastryType: this.synastryType,
          SetupConfigs: this.detailNeed_fixed,
        });
        this.interpretations = result.Data.Interpretations;
        console.log("interpretations", this.interpretations);
        this.scoreStepNumber = result.Data.StepNumber;
      } catch (e) {
        // console.error("API 取得合盤解讀失敗", e);
      }
    },
    changeShow(index) {
      this.canshow = [false, false, false];
      this.canshow[index] = true;
      setTimeout(() => {
        this.canshow = [false, false, false];
      }, 800);
    },
    //星盤設置開關
    openSetup() {
      this.activeSetup = !this.activeSetup;
    },
    //星盤設置更新
    async refreshData() {
      this.dataChange = false;
      setTimeout(() => {
        this.dataChange = true;
      }, 50);
    },
    changeTypeHandler($event) {
      this.showType = $event;
      if (this.showType == "type1") {
        this.changeType1();
      } else if (this.showType == "type3") {
        this.changeType3();
      }
    },
    changeType1() {
      this.Data1 = this.target1.AstralInfo;
      this.Data2 = this.target2.AstralInfo;
      this.paramPan.type = 1;
      this.synastryType = 0;
      this.refreshData();
    },
    changeType2() {
      this.Data1 = this.target2.AstralInfo;
      this.Data2 = this.target1.AstralInfo;
      this.paramPan.type = 1;
      this.synastryType = 0;
      this.refreshData();
    },
    changeType3() {
      let compare1 = this.target1.AstralInfo;
      let compare2 = this.target2.AstralInfo;
      this.paramPan.type = 0;
      this.synastryType = 1;
      let composeData = [];
      compare1.AstralRadians.forEach((item, index) => {
        composeData[index] =
          (Number(compare1.AstralRadians[index]) +
            Number(compare2.AstralRadians[index])) /
          2;
        if (
          Number(compare1.AstralRadians[index]) <
            Number(compare2.AstralRadians[index]) &&
          Number(compare1.AstralRadians[index]) < 180
        ) {
          composeData[index] = (composeData[index] + 180) % 360;
        }
      });
      this.Data1 = {
        ...this.target1.AstralInfo,
        AstralRadians: composeData,
      };
      this.Data2 = null;
      this.paramPan.type = 0;
      this.refreshData();
    },
    toListDBL() {
      this.$router.push("/listDBL");
    },
    getProfilePhotoSrc(constellation) {
      let obj = this.$Astro.find((item) => item.en == constellation);
      return obj ? obj.img : "";
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.SynastryMain {
  max-width: 1056px;
  @include align(space-around, flex-start);
  margin: 36px auto;
  @include laptop {
    flex-direction: column;
    @include center;
    .SynastryMain__interpret {
      order: 2;
    }
  }
  @include pad {
    margin: 0 auto;
    padding: 0;
  }

  &__interpret {
    width: 44%; //448px
    @include align(flex-start, flex-start);
    flex-direction: column;
    margin-right: 30px;

    @include laptop {
      margin-right: 0;
      margin-top: 30px;
      width: 80%;
    }

    @include pad {
      width: 100%;
      padding: 0 16px;
    }

    &__tab {
      margin-bottom: 28px;
      @include mobile {
        margin-bottom: 40px;
      }
    }
  }

  //主要內容
  &__content {
    width: 52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include laptop {
      width: 80%;
    }
    @include pad {
      width: 100%;
    }
    @include mobile{
      margin-top: 32px;
    }

    .astrolabeDBL {
      margin-top: 30px;
      height: auto;
      width: 100%;
      position: relative;
      background-color: #f0f2f4;
      border-radius: 0;
      box-shadow: -2px -2px 8px 0 #fff, -4px -4px 12px 0 #fff,
        4px 4px 20px 0 rgba(0, 0, 0, 0.2);
      padding: 30px 0;
      border-radius: 20px;

      @include pad {
        padding: 0;
        box-shadow: none;
      }

      &__setting,
      &__change {
        @include rect-s(40px, 40px, $theme-color-3, 50%);
        @include center;
        z-index: 10;
        position: absolute;
        cursor: pointer;
      }

      &__setting {
        left: 5%;
        top: 24px;

        img {
          width: 65%;
          height: 65%;
        }

        @include pad {
          left: 4%;
          top: 0;
        }
      }

      &__change {
        right: 5%;
        top: 95px;

        img {
          width: 90%;
          height: 90%;
        }

        @include pad {
          right: 4%;
          top: 0;
        }
      }

      .pan_type {
        margin-bottom: 42px;
      }

      &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 510px;
        height: 510px;
        border-radius: 50%;
        // box-shadow: inset -2px -4px 10px 0 #ffffff, inset -2px -4px 10px 0 #ffffff,
        //   inset 0 0 10px 0 rgba(0, 0, 0, 0.45);
        background-color: $theme-color-3;

        @include laptop {
          width: 760px;
          height: 760px;
        }

        @include pad {
          width: 100vw;
          height: 100vw;
        }
      }
    }

    //星盤swiper
    #SW {
      .astrolabeDBL__item {
        @include center;
      }
    }

    .switch {
      @include center;
      margin-top: 50px;

      @include pad {
        margin-top: 20px;
      }

      .swiper-button-prev {
        margin: 0;
        position: relative;
        background-image: url("~@/assets/icon_prev.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 48px;
        height: 48px;
        outline: none;
        left: 0;
        bottom: 0;

        @include mobile {
          width: 32px;
          height: 32px;
        }
      }

      .swiper-button-next {
        margin: 0;
        position: relative;
        background-image: url("~@/assets/icon_next.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 48px;
        height: 48px;
        outline: none;
        right: 0;
        bottom: 0;

        @include mobile {
          width: 32px;
          height: 32px;
        }
      }

      .swiper-pagination {
        position: relative;
        @include center;
        margin: 0 64px;

        @include mobile {
          margin: 0 40px;
        }
      }
    }

    //僅手機板出現
    &__tab {
      margin-top: 44px;
      padding: 0 16px;
    }
  }
}
</style>