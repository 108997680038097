var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tabList
    ? _c("div", { staticClass: "tab" }, [
        _c(
          "div",
          {
            staticClass: "tab__item",
            class: { activeTab: _vm.activeTab.value == _vm.tabList[0].value },
            on: {
              click: function($event) {
                _vm.activeTab = _vm.tabList[0]
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.tabList[0].label) + "\n  ")]
        ),
        _c(
          "div",
          {
            staticClass: "tab__item",
            class: { activeTab: _vm.activeTab.value == _vm.tabList[1].value },
            on: {
              click: function($event) {
                _vm.activeTab = _vm.tabList[1]
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.tabList[1].label) + "\n  ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }